<template>
  <wrapper-reveal
    component-name="card-cta"
    data-skin="black"
    class="@container/card-cta flex flex-col tablet:flex-row tablet:items-stretch"
    reveal="slide-from-bottom reveal-strong"
  >
    <!-- MEDIA -->
    <header
      tag="header"
      class="relative top-0 left-0 w-full overflow-hidden tablet:w-5/12"
    >
      <media
        ref="tpl_media"
        v-bind="{
          ...asset,
          aspectRatio: forceVerticalMedia ? '4 / 5' : asset.aspectRatio,
        }"
        class="w-full min-h-full"
        reveal="unveil-from-right"
        :use-bg="false"
      />
    </header>
    <!-- end MEDIA -->

    <!--  TEXTS -->
    <div
      class="relative top-0 left-0 flex items-center w-full max-w-full px-edge py-3xl tablet:p-lg aspect-1/1 phablet:aspect-3/2 tablet:aspect-none tablet:w-7/12 bg-skin-base text-skin-base"
    >
      <div
        class="flex flex-col items-stretch justify-center w-full mx-auto phablet:w-11/12 tablet:w-10/12"
      >
        <wrapper-reveal v-if="overline" reveal="fade-from-right">
          <div
            v-html="overline"
            class="text-overline-small @[900px]/card-cta:text-overline"
          ></div>
        </wrapper-reveal>

        <wrapper-split-text
          reveal="chars"
          :class="{
            'mt-xs': overline,
          }"
        >
          <div
            v-html="title"
            class="text-heading-4 @[900px]/card-cta:text-heading-3"
          ></div>
        </wrapper-split-text>

        <wrapper-reveal v-if="text" reveal="fade-from-bottom" class="mt-lg">
          <div
            v-html="text"
            class="text-body-small @[900px]/card-cta:text-body"
          ></div>
        </wrapper-reveal>

        <wrapper-reveal
          reveal="fade-from-right"
          class="self-start mt-lg phablet:mt-xl"
        >
          <btn-pill
            size="small"
            :title="button.title"
            :action="button.action || undefined"
            :icon="
              button.action?.roomvoVisualizer
                ? 'configurator'
                : 'arrow-long-right'
            "
            :use-tracking="
              $route.name === 'country-locale-search' &&
              button.action?.to === '/products/find-product'
                ? {
                    event: 'click_sottovoce_trova_prodotto',
                  }
                : undefined
            "
          />
        </wrapper-reveal>
      </div>
    </div>
    <!-- end TEXTS -->
  </wrapper-reveal>
</template>

<script setup>
const props = defineProps({
  overline: {
    type: String,
    required: false,
  },
  title: {
    type: String,
    required: true,
  },
  text: {
    type: String,
    required: false,
  },
  button: {
    type: Object,
    required: true,
  },
  asset: {
    type: Object,
    required: true,
  },
  forceVerticalMedia: {
    type: Boolean,
    required: false,
  },
});
</script>
